import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseSemester: [],
  courseBatch: [],
  superSetupTypeValue: [],
};

const responsesSlice = createSlice({
  name: "responses",
  initialState,
  reducers: {
    UpdateCourseSemester: (state, action) => {
      state.courseSemester = action.payload;
    },
    UpdateCourseBatch: (state, action) => {
      state.courseBatch = action.payload;
    },
    UpdateSuperSetupTypeValue: (state, action) => {
      state.superSetupTypeValue = action.payload;
    },
  },
});
export default responsesSlice.reducer;
export const {
  UpdateCourseSemester,
  UpdateCourseBatch,
  UpdateSuperSetupTypeValue,
} = responsesSlice.actions;
