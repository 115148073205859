export const getDesignTheme = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            light: "#a36ef4",
            main: "#8C4AF2",
            dark: "#6233a9",
          },
          secondary: {
            light: "#f5f6fa",
            main: "#e5eaff",
            dark: "#ddc7ff",
          },
          contras: {
            main: "#000000",
          },
          current: {
            main: "#ffffff",
          },
          disable: {
            light: "#888888",
            main: "#8888884d",
            dark: "#636678",
            contrastText: "#fff",
          },
          background: {
            default: "#ffffff",
            dark: "#2a3042",
            navBar: "#ffffff",
            sideBar: "#EFEFEF",
            sideBar2: "#DfDfDf",
            card: "#d2affd",
            card2: "#eff2f7",
            card3: "#f8fafb",
          },
          icon: {
            green: "#68B34E",
            dark: "#069e10",
            warning: "#FFA800",
            error: "#f46a6a",
            sidebar: "#EFEFEF",
            sidebar1: "#ffffff",
            fieldErr: "#F83630",
            blue: "#6b56f6",
          },
          text: {
            head: "#323139",
            normal: "#4d4d4d",
            primary: "#212121",
            secondary: "#878787",
            contrasPrimary: "#ffffff",
            contrasSecondary: "#ffffff88",
            sidebar: "#7c7c7c",
            dashboard: "#545454",
          },
          border: {
            main: "#eff2f7",
            primary: "#495057",
            scroll: " #666e7c",
            input: "#7443C4",
          },
          shadow: {
            navBar: "#12263f08",
          },
          color: {
            pink: {
              primary: "#FBE6F1",
              secondary: "#f5c2dc",
            },
            orange: { primary: "#fef2e2", secondary: "#ffd399" },
            green: { primary: "#CDFFCE", secondary: "#94ff96" },
            yellow: { primary: "#fffce5", secondary: "#fff394" },
            purple: { primary: "#f0e2fe", secondary: "#cc99ff" },
            red: { primary: "#ffe5e5", secondary: "#ff9999" },
            blue: { primary: "#e2e9fe", secondary: "#adc2ff" },
            clayBlue: { primary: "#e3f3fc", secondary: "#94d8ff" },
            voilet: { primary: "#e2dffb", secondary: "#9f94ff" },
            grey: "#DFE4FF",
          },
          attendance: {
            light: "#93a5ff",
            present: "#3CCF4E",
            leave: "#EF5B0C",
            absent: "#C21010",
            sports: "#277BC0",
            medical: "#1CD6CE",
            "first half": "#F6A192",
            half: "#F6A192",
            "second half": "#EA6823",
            chip: "#00000014",
          },
          button: { main: "#9080f8", light: "#efedfe" },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#EA6823",
          },
          secondary: {
            main: "#000",
          },
          background: {
            default: "#222736",
          },
        }),
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  selected: {},
});

// export const getDesignTheme = (mode) => ({
//   palette: {
//     mode,
//     ...(mode === "light"
//       ? {
//           // palette values for light mode
//           primary: {
//             light: "#B3D4FF", // Light Blue
//             main: "#007BFF", // Blue
//             dark: "#0056B3", // Dark Blue
//           },
//           secondary: {
//             light: "#E9ECEF", // Light Gray
//             main: "#6C757D", // Gray
//             dark: "#343A40", // Dark Gray
//           },
//           contras: {
//             main: "#000000",
//           },
//           current: {
//             main: "#ffffff",
//           },
//           disable: {
//             light: "#888888", // Gray for disabled elements
//             main: "#8888884d",
//             dark: "#636678",
//             contrastText: "#fff", // White text for disabled elements
//           },
//           background: {
//             default: "#ffffff", // White background
//             dark: "#2a3042", // Dark background
//             navBar: "#ffffff", // Blue navbar
//             sideBar: "#EFEFEF", // Light Gray sidebar
//             sideBar2: "#DfDfDf", // Slightly darker Gray sidebar
//             card: "#B3D4FF", // Light Blue card
//             card2: "#EFF2F7", // Very Light Gray card
//             card3: "#F8FAFB", // Off-White card
//           },
//           icon: {
//             green: "#68B34E", // Green icon
//             dark: "#0056B3", // Dark Blue icon
//             warning: "#FFA800", // Yellow warning icon
//             error: "#F46A6A", // Red error icon
//             sidebar: "#EFEFEF", // Light Gray sidebar icon
//             sidebar1: "#ffffff", // White sidebar icon
//             fieldErr: "#F83630", // Red error icon for fields
//             blue: "#007BFF", // Blue icon
//           },
//           text: {
//             head: "#323139", // Dark Gray heading text
//             normal: "#4d4d4d", // Medium Gray normal text
//             primary: "#212121", // Dark Gray primary text
//             secondary: "#878787", // Light Gray secondary text
//             contrastPrimary: "#ffffff", // White contrast text
//             contrastSecondary: "#ffffff88", // Semi-transparent white contrast text
//             sidebar: "#7c7c7c", // Gray sidebar text
//             dashboard: "#545454", // Medium Gray dashboard text
//           },
//           border: {
//             main: "#EFF2F7", // Very Light Gray border
//             primary: "#495057", // Medium Gray border
//             scroll: "#666E7C", // Dark Gray scroll
//             input: "#007BFF", // Blue input border
//           },
//           shadow: {
//             navBar: "#12263F08", // Light shadow for navbar
//           },
//           attendance: {
//             light: "#93A5FF", // Light Blue attendance
//             present: "#3CCF4E", // Green present
//             leave: "#EF5B0C", // Orange leave
//             absent: "#C21010", // Red absent
//             sports: "#277BC0", // Blue sports
//             medical: "#1CD6CE", // Teal medical
//             "first half": "#F6A192", // Light Orange first half
//             half: "#F6A192", // Light Orange half
//             "second half": "#EA6823", // Dark Orange second half
//             chip: "#00000014", // Semi-transparent black chip
//           },
//           button: {
//             main: "#007BFF", // Blue button
//             light: "#E9ECEF", // Light Gray button
//           },
//         }
//       : {
//           // palette values for dark mode
//           primary: {
//             main: "#EA6823", // Dark mode primary
//           },
//           secondary: {
//             main: "#000000", // Dark mode secondary
//           },
//           background: {
//             default: "#222736", // Dark mode background
//           },
//         }),
//   },
//   typography: {
//     fontFamily: ["Poppins", "sans-serif"].join(","),
//   },
//   selected: {},
// });

// Theme 1: Professional and Clean
// Light Mode
// Primary: Shades of blue
// Secondary: Light gray tones
// Text: Dark gray and black
// export const getDesignTheme = (mode) => ({
//   palette: {
//     mode,
//     ...(mode === "light"
//       ? {
//           primary: {
//             light: "#5c8ecf",
//             main: "#2a6ebb",
//             dark: "#1b4b8b",
//           },
//           secondary: {
//             light: "#f1f1f1",
//             main: "#e0e0e0",
//             dark: "#b0b0b0",
//           },
//           contras: {
//             main: "#000000",
//           },
//           current: {
//             main: "#ffffff",
//           },
//           disable: {
//             light: "#bcbcbc",
//             main: "#bcbcbc4d",
//             dark: "#8c8c8c",
//             contrastText: "#fff",
//           },
//           background: {
//             default: "#ffffff",
//             dark: "#2a3042",
//             navBar: "#ffffff",
//             sideBar: "#f5f5f5",
//             sideBar2: "#e5e5e5",
//             card: "#d3e3fc",
//             card2: "#eff2f7",
//             card3: "#f8fafb",
//           },
//           icon: {
//             green: "#57a773",
//             dark: "#046e54",
//             warning: "#ff9800",
//             error: "#e57373",
//             sidebar: "#f5f5f5",
//             sidebar1: "#ffffff",
//             fieldErr: "#d32f2f",
//             blue: "#3f51b5",
//           },
//           text: {
//             head: "#333333",
//             normal: "#4d4d4d",
//             primary: "#212121",
//             secondary: "#757575",
//             contrasPrimary: "#ffffff",
//             contrasSecondary: "#ffffff88",
//             sidebar: "#7c7c7c",
//             dashboard: "#545454",
//           },
//           border: {
//             main: "#e0e0e0",
//             primary: "#455a64",
//             scroll: "#888888",
//             input: "#3f51b5",
//           },
//           shadow: {
//             navBar: "#0000000a",
//           },
//           attendance: {
//             light: "#b2d1ff",
//             present: "#66bb6a",
//             leave: "#ff7043",
//             absent: "#d32f2f",
//             sports: "#1e88e5",
//             medical: "#00acc1",
//             "first half": "#ffab91",
//             half: "#ffab91",
//             "second half": "#ff7043",
//             chip: "#00000014",
//           },
//           button: { main: "#2a6ebb", light: "#e3f2fd" },
//         }
//       : {
//           primary: {
//             main: "#2a6ebb",
//           },
//           secondary: {
//             main: "#e0e0e0",
//           },
//           background: {
//             default: "#222736",
//           },
//         }),
//   },
//   typography: {
//     fontFamily: ["Poppins", "sans-serif"].join(","),
//   },
//   selected: {},
// });

// Theme 2: Modern and Sleek
// Light Mode
// Primary: Shades of teal
// Secondary: Light beige tones
// Text: Dark gray and black
// export const getDesignTheme = (mode) => ({
//   palette: {
//     mode,
//     ...(mode === "light"
//       ? {
//           primary: {
//             light: "#4fd0b5",
//             main: "#26a69a",
//             dark: "#1a7a6c",
//           },
//           secondary: {
//             light: "#f5f5dc",
//             main: "#eee8aa",
//             dark: "#dcdcdc",
//           },
//           contras: {
//             main: "#000000",
//           },
//           current: {
//             main: "#ffffff",
//           },
//           disable: {
//             light: "#a9a9a9",
//             main: "#a9a9a94d",
//             dark: "#696969",
//             contrastText: "#fff",
//           },
//           background: {
//             default: "#ffffff",
//             dark: "#2a3042",
//             navBar: "#ffffff",
//             sideBar: "#fafafa",
//             sideBar2: "#f0f0f0",
//             card: "#b2dfdb",
//             card2: "#e0f2f1",
//             card3: "#f8fafb",
//           },
//           icon: {
//             green: "#66bb6a",
//             dark: "#388e3c",
//             warning: "#ffa726",
//             error: "#ef5350",
//             sidebar: "#fafafa",
//             sidebar1: "#ffffff",
//             fieldErr: "#d32f2f",
//             blue: "#26c6da",
//           },
//           text: {
//             head: "#333333",
//             normal: "#4d4d4d",
//             primary: "#212121",
//             secondary: "#757575",
//             contrasPrimary: "#ffffff",
//             contrasSecondary: "#ffffff88",
//             sidebar: "#7c7c7c",
//             dashboard: "#545454",
//           },
//           border: {
//             main: "#e0e0e0",
//             primary: "#455a64",
//             scroll: "#888888",
//             input: "#26a69a",
//           },
//           shadow: {
//             navBar: "#0000000a",
//           },
//           attendance: {
//             light: "#b2dfdb",
//             present: "#66bb6a",
//             leave: "#ff7043",
//             absent: "#d32f2f",
//             sports: "#1e88e5",
//             medical: "#00acc1",
//             "first half": "#ffab91",
//             half: "#ffab91",
//             "second half": "#ff7043",
//             chip: "#00000014",
//           },
//           button: { main: "#26a69a", light: "#e0f2f1" },
//         }
//       : {
//           primary: {
//             main: "#26a69a",
//           },
//           secondary: {
//             main: "#eee8aa",
//           },
//           background: {
//             default: "#222736",
//           },
//         }),
//   },
//   typography: {
//     fontFamily: ["Poppins", "sans-serif"].join(","),
//   },
//   selected: {},
// });

// Theme 3: Bold and Vibrant
// Light Mode
// Primary: Shades of orange
// Secondary: Light blue tones
// Text: Dark gray and black
// export const getDesignTheme = (mode) => ({
//   palette: {
//     mode,
//     ...(mode === "light"
//       ? {
//           primary: {
//             light: "#ffcc80",
//             main: "#ff9800",
//             dark: "#e65100",
//           },
//           secondary: {
//             light: "#e0f7fa",
//             main: "#b2ebf2",
//             dark: "#80deea",
//           },
//           contras: {
//             main: "#000000",
//           },
//           current: {
//             main: "#ffffff",
//           },
//           disable: {
//             light: "#a9a9a9",
//             main: "#a9a9a94d",
//             dark: "#696969",
//             contrastText: "#fff",
//           },
//           background: {
//             default: "#ffffff",
//             dark: "#2a3042",
//             navBar: "#ffffff",
//             sideBar: "#fafafa",
//             sideBar2: "#f0f0f0",
//             card: "#ffecb3",
//             card2: "#fff3e0",
//             card3: "#f8fafb",
//           },
//           icon: {
//             green: "#81c784",
//             dark: "#388e3c",
//             warning: "#ffa726",
//             error: "#e57373",
//             sidebar: "#fafafa",
//             sidebar1: "#ffffff",
//             fieldErr: "#d32f2f",
//             blue: "#4fc3f7",
//           },
//           text: {
//             head: "#333333",
//             normal: "#4d4d4d",
//             primary: "#212121",
//             secondary: "#757575",
//             contrasPrimary: "#ffffff",
//             contrasSecondary: "#ffffff88",
//             sidebar: "#7c7c7c",
//             dashboard: "#545454",
//           },
//           border: {
//             main: "#e0e0e0",
//             primary: "#455a64",
//             scroll: "#888888",
//             input: "#ff9800",
//           },
//           shadow: {
//             navBar: "#0000000a",
//           },
//           attendance: {
//             light: "#ffecb3",
//             present: "#66bb6a",
//             leave: "#ff7043",
//             absent: "#d32f2f",
//             sports: "#1e88e5",
//             medical: "#00acc1",
//             "first half": "#ffab91",
//             half: "#ffab91",
//             "second half": "#ff7043",
//             chip: "#00000014",
//           },
//           button: { main: "#ff9800", light: "#fff3e0" },
//         }
//       : {
//           primary: {
//             main: "#ff9800",
//           },
//           secondary: {
//             main: "#b2ebf2",
//           },
//           background: {
//             default: "#222736",
//           },
//         }),
//   },
//   typography: {
//     fontFamily: ["Poppins", "sans-serif"].join(","),
//   },
//   selected: {},
// });

// export const getDesignTheme = (mode) => ({
//   palette: {
//     mode,
//     ...(mode === "light"
//       ? {
//           // palette values for light mode
//           primary: {
//             light: "#a36ef4",
//             main: "#8C4AF2",
//             dark: "#6233a9",
//           },
//           secondary: {
//             light: "#f5f6fa",
//             main: "#e5eaff",
//             dark: "#ddc7ff",
//           },
//           contras: {
//             main: "#000000",
//           },
//           current: {
//             main: "#ffffff",
//           },
//           disable: {
//             light: "#888888",
//             main: "#8888884d",
//             dark: "#636678",
//             contrastText: "#fff",
//           },
//           background: {
//             default: "#ffffff",
//             dark: "#2a3042",
//             navBar: "#ffffff",
//             sideBar: "#EFEFEF",
//             sideBar2: "#DfDfDf",
//             card: "#d2affd",
//             card2: "#eff2f7",
//             card3: "#f8fafb",
//           },
//           icon: {
//             green: "#68B34E",
//             dark: "#069e10",
//             warning: "#FFA800",
//             error: "#f46a6a",
//             sidebar: "#EFEFEF",
//             sidebar1: "#ffffff",
//             fieldErr: "#F83630",
//             blue: "#6b56f6",
//           },
//           text: {
//             head: "#323139",
//             normal: "#4d4d4d",
//             primary: "#212121",
//             secondary: "#878787",
//             contrasPrimary: "#ffffff",
//             contrasSecondary: "#ffffff88",
//             sidebar: "#7c7c7c",
//             dashboard: "#545454",
//           },
//           border: {
//             main: "#eff2f7",
//             primary: "#495057",
//             scroll: " #666e7c",
//             input: "#7443C4",
//           },
//           shadow: {
//             navBar: "#12263f08",
//           },
//           attendance: {
//             light: "#93a5ff",
//             present: "#3CCF4E",
//             leave: "#EF5B0C",
//             absent: "#C21010",
//             sports: "#277BC0",
//             medical: "#1CD6CE",
//             "first half": "#F6A192",
//             half: "#F6A192",
//             "second half": "#EA6823",
//             chip: "#00000014",
//           },
//           button: { main: "#9080f8", light: "#efedfe" },
//         }
//       : {
//           // palette values for dark mode
//           primary: {
//             main: "#EA6823",
//           },
//           secondary: {
//             main: "#000",
//           },
//           background: {
//             default: "#222736",
//           },
//         }),
//   },
//   typography: {
//     fontFamily: ["Poppins", "sans-serif"].join(","),
//   },
//   selected: {},
// });
